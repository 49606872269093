<template>
    <v-card class="pa-3">
        <v-row>
            <v-col>
                <h4 class="bold">Apontamento</h4>
            </v-col>
            <v-col class="d-flex justify-end">
                <select-actions
                    @executeAction="callMethod"
                    :actions="[
                        {
                            name: 'Editar Dados',
                            method: 'editarApontamento',
                            disabled: checkPermission
                        },
                    ]"
                />
            </v-col>
        </v-row>
        <div v-if="titulo.apontamento_nu_protocolo" class="mt-5">
            <v-row>
                <v-col cols="4" class="pb-3">
                    <v-text-field
                        dense
                        outlined
                        :value="titulo.apontamento_nu_protocolo"
                        label="Protocolo do cartório"
                        readonly
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col class="pb-3">
                    <v-text-field
                        outlined
                        dense
                        :value="
                            moment(titulo.apontamento_dt_protocolo).format(
                                'DD/MM/YYYY'
                            )
                        "
                        label="Data de apontamento"
                        readonly
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col class="pb-3">
                    <v-text-field
                        outlined
                        dense
                        :value="
                            titulo.apontamento_cd_municipio +
                                ' - ' +
                                titulo.nm_municipio_apontamento
                        "
                        label="Municipio de apontamento"
                        hide-details="auto"
                        readonly
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-col class="pb-3">
                    <v-text-field
                        outlined
                        dense
                        :value="titulo.nm_cartorio_apontamento"
                        label="Cartório"
                        readonly
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-col class="pb-3">
                    <v-text-field
                        outlined
                        dense
                        :value="titulo.ds_endereco_sacador"
                        label="Endereço do cartório"
                        hide-details="auto"
                        readonly
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-col class="pb-3">
                    <v-text-field
                        outlined
                        dense
                        :value="cartorioInfos.bairro"
                        label="Bairro"
                        hide-details="auto"
                        readonly
                    ></v-text-field>
                </v-col>
                <v-col class="pb-3">
                    <v-text-field
                        outlined
                        dense
                        :value="cartorioInfos.nm_cidade"
                        label="Cidade"
                        hide-details="auto"
                        readonly
                    ></v-text-field>
                </v-col>
                <v-col cols="2" class="pb-3">
                    <v-text-field
                        outlined
                        dense
                        :value="cartorioInfos.cd_uf"
                        label="UF"
                        hide-details="auto"
                        readonly
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-col class="pb-3">
                    <v-text-field
                        outlined
                        dense
                        :value="titulo.email_cartorio"
                        label="E-mail do cartório"
                        readonly
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col class="pb-3">
                    <v-text-field
                        outlined
                        dense
                        :value="titulo.telefone_cartorio"
                        label="Telefone do cartório"
                        hide-details="auto"
                        readonly
                    ></v-text-field>
                </v-col>
            </v-row>
        </div>
        <div v-else class="feedback">
            <v-alert prominent icon="mdi-file-document" shaped color="#f5f5f5">
                Este título ainda não foi protocolado/apontado em cartório
            </v-alert>
        </div>
        <v-dialog v-model="modalApontamento" max-width="800px">
            <v-card class="pa-3">
                <v-row>
                    <v-col>
                        <h4 class="bold">Apontamento</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card class="pa-2">
                            <v-row>
                                <v-col lg="6">
                                    <v-icon small class="ma-2">
                                        mdi-home-city
                                    </v-icon>
                                    Número do Protocolo <br />
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="novoProtocolo"
                                        label="Protocolo do Cartório"
                                        required
                                        maxlength="10"
                                        :onkeypress="
                                            permitirLetrasNumeros(false)
                                        "
                                    ></v-text-field>
                                </v-col>
                                <v-col lg="6">
                                    <v-icon small class="ma-2">
                                        mdi-home-city
                                    </v-icon>
                                    Data do Apontamento <br />
                                    <v-menu
                                        v-model="menu2"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        min-width="auto"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-text-field
                                                outlined
                                                dense
                                                v-model="dataDigitavel"
                                                label="Clique para selecionar"
                                                required
                                                locale="pt-br"
                                                v-maska="'##/##/####'"
                                                prepend-icon="mdi-calendar"
                                                hint="Formato: dd/mm/AAAA"
                                                class="parcelas"
                                                v-bind="attrs"
                                                v-on="on"
                                                @keyup.enter="
                                                    validarDataDigitada(
                                                        true,
                                                        $event
                                                    )
                                                "
                                                @blur="validarDataDigitada()"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="data"
                                            locale="pt-br"
                                            color="primary"
                                            @input="selecionarData(data)"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-0">
                        <v-card class="pa-2">
                            <v-card-text class="ma-2 pa-2">
                                <v-icon small class="ma-2">
                                    mdi-home-city
                                </v-icon>
                                Selecione o novo Município
                            </v-card-text>
                            <v-row>
                                <v-col lg="1">
                                    <v-progress-circular
                                        v-show="loadingMunicipios"
                                        color="primary"
                                        small
                                        indeterminate
                                        class="ml-5"
                                    ></v-progress-circular>
                                    <v-icon
                                        class="ml-5"
                                        large
                                        v-show="iconLateral"
                                        >mdi-chevron-right</v-icon
                                    >
                                </v-col>
                                <v-col lg="8">
                                    <v-autocomplete
                                        v-model="valueMunicipios"
                                        :items="itensMunicipios.municipios"
                                        dense
                                        :loading="loadingMunicipios"
                                        :disabled="loadingMunicipios"
                                        @input="buscaCartorio"
                                        item-text="nm_estado_municipio"
                                        item-value="cd_municipio_completo"
                                        outlined
                                        label="Selecione o Município"
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col lg="3">
                                    <v-autocomplete
                                        v-model="valueMunicipios"
                                        :items="itensMunicipios.municipios"
                                        dense
                                        outlined
                                        item-text="cd_ibge_comarca"
                                        item-value="cd_municipio_completo"
                                        @input="buscaCartorio"
                                        label="Comarca"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-3">
                        <v-card class="pa-2">
                            <v-card-text class="ma-2 pa-2">
                                <v-icon small class="ma-2"
                                    >mdi-home-city</v-icon
                                >
                                Selecione o novo Cartório
                            </v-card-text>
                            <v-row>
                                <v-col lg="1">
                                    <v-progress-circular
                                        v-show="loadingCartorios"
                                        color="primary"
                                        indeterminate
                                        class="ml-5"
                                    ></v-progress-circular>
                                    <v-icon
                                        class="ml-5"
                                        large
                                        v-show="iconLateralCartorios"
                                        >mdi-chevron-right</v-icon
                                    >
                                </v-col>
                                <v-col lg="8">
                                    <v-autocomplete
                                        v-model="valueCartorios"
                                        :items="itemsCartorios"
                                        dense
                                        no-data-text="Nenhum cartório encontrado para esse município"
                                        item-text="nm_cartorio"
                                        item-value="nu_cartorio"
                                        outlined
                                        label="Selecione o Cartório"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col lg="3">
                                    <v-select
                                        v-model="valueCartorios"
                                        :items="itemsCartorios"
                                        dense
                                        outlined
                                        item-text="nu_cartorio"
                                        item-value="nu_cartorio"
                                        label="Número do Cartório"
                                    >
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col align="right">
                        <v-btn
                            class="mr-3"
                            color="success"
                            @click="salvarDadosComarca"
                        >
                            Salvar
                        </v-btn>
                        <v-btn @click="modalApontamento = false">
                            Cancelar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <Snackbar
            v-if="snackbarDialog"
            :mostrarSnackbar="snackbarDialog"
            :corSnackbar="snackbarColor"
            :mensagemSnackbar="mensagemSnackBar"
            @fecharSnackbar="snackbarDialog = false"
        /> 
        
    </v-card>
</template>
<script>
import SelectActions from '../atoms/SelectActions.vue';
import moment from 'moment';
import { mask } from 'maska';
import TituloService from '@/services/TituloService';
import Vue from 'vue';

import {
    getDataAtualDatePicker,
    permitirLetrasNumeros,
    validarFormatoData
} from '@/helpers/Utilitarios';
import lodash from 'lodash';
import CartorioService from '@/services/CartorioService';

export default {
    name: 'CardApontamento',
    components: {
        SelectActions
    },
    props: {
        titulo: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            modalApontamento: false,
            cartorioService: new CartorioService(Vue.http, this.$store),
            snackbarTimeout: 6000,
            editarCartorio: false,
            desabilitaCartorios: false,
            mensagemSnackBar: '',
            novoProtocolo: '',
            data: null,
            dataDigitavel: null,
            formatDatacartorioInline: '',
            valueMunicipios: '',
            menu2: null,
            cartorioTelefone: '',
            loadingMunicipios: false,
            loadingCartorios: false,
            iconLateral: null,
            iconLateralCartorios: null,
            cartorioEmail: '',
            editarCartorioComarca: false,
            itemsCartorios: [],
            valueCartorios: null,
            snackbarDialog: false,
            snackbarColor: '',
            snackbarIcon: '',
            usuarioPartner: false,
            usuarioRecuperi: false,
            superUsuario: false,
            cartorioInfos:{}
        };
    },
    async mounted() {
        if (this.$store.getters.isPartner == 1) {
            this.usuarioPartner = true;
        } else {
            this.usuarioRecuperi = true;
        }
        if (this.$store.getters.getSuperUser == 1) {
            this.superUsuario = true;
        } else {
            this.superUsuario = false;
        }
        if(this.titulo.apontamento_cd_municipio){
            this.getCartorioApontamento(this.titulo.apontamento_cd_municipio);
        }
    },
    created() {
        moment.locale('pt-br');
    },
    computed: {
        checkPermission() {
            return this.superUsuario && this.usuarioRecuperi ? false : true;
        },
        telefoneCartorio() {
            if (this.cartorioTelefone.length === 10) {
                return mask(this.cartorioTelefone, '(##) ####-####');
            } else {
                return mask(this.cartorioTelefone, '(##) #####-####');
            }
        },
        itensMunicipios() {
            return this.$store.getters.itensMunicipios;
        },
        listaMunicipios() {
            return this.$store.getters.listaMunicipios;
        }
    },
    methods: {
        moment,
        validarFormatoData,
        permitirLetrasNumeros,
        getDataAtualDatePicker,
        getCartorioApontamento(){
            this.cartorioService.buscarCartorio(this.titulo.apontamento_cd_municipio, Number(this.titulo.apontamento_nu_cartorio)).then(response => {
                this.cartorioInfos = response[0];
            });
        },
        async buscaCartorio() {
            this.valueCartorios = null;
            this.editarCartorioComarca = true;
            let cidade = this.valueMunicipios;
            this.loadingCartorios = true;
            let listaCartorios = new TituloService(Vue.http, this.$store);
            await listaCartorios.getCartorios(cidade).then(response => {
                this.loadingCartorios = false;
                this.iconLateralCartorios = true;
                this.itemsCartorios = response.body;
            });
        },
        validarPreenchimentoApontamento() {
            if (
                this.novoProtocolo &&
                this.dataApontamento &&
                this.valueMunicipios &&
                this.valueCartorios
            ) {
                return true;
            }
            return false;
        },
        salvarDadosComarca() {
            if (this.validarPreenchimentoApontamento()) {
                let id_titulo = this.titulo.id_titulo;
                let protocolo = this.novoProtocolo;
                let cd_apresentante = this.titulo.cd_apresentante;
                if (this.titulo.cd_comprador) {
                    cd_apresentante = this.titulo.cd_comprador;
                }
                let data = this.dataApontamento.split('/');
                let dia = data[0];
                let mes = data[1];
                let ano = data[2];
                let dataApontamento = ano + '-' + mes + '-' + dia;
                let cartorio = this.valueCartorios;
                let municipio = this.valueMunicipios;
                return new Promise((resolve, reject) => {
                    try {
                        let tituloService = new TituloService(
                            Vue.http,
                            this.$store
                        );
                        tituloService
                            .salvarComarca(
                                protocolo,
                                dataApontamento,
                                cartorio,
                                municipio,
                                id_titulo,
                                cd_apresentante
                            )
                            .then(
                                response => {
                                    console.log(response);
                                    this.editarCartorio = false;
                                    // campos de texto
                                    this.novoProtocolo = null;
                                    this.dataApontamento = null;
                                    this.valueMunicipios = null;
                                    this.valueCartorios = null;
                                    // cards
                                    this.desabilitaCartorios = false;
                                    this.editarCartorioComarca = false;
                                    this.editarCartorio = false;
                                    this.emitirSnackbar(
                                        'green',
                                        'Título atualizado com Sucesso'
                                    );
                                    this.modalApontamento = false;
                                    this.$emit('atualizaTitulo');
                                },
                                error => {
                                    console.log(error);
                                    this.editarCartorio = false;
                                    this.desabilitaCartorios = false;
                                    this.emitirSnackbar(
                                        'error',
                                        'Erro ao realizar solicitação'
                                    );
                                }
                            );
                    } catch (e) {
                        this.mensagem = e;
                        console.log(e);
                        reject();
                    }
                });
            } else {
                this.emitirSnackbar(
                    'error',
                    'É preciso preencher todos os campos do Apontamento'
                );
            }
        },
        emitirSnackbar(
            snackbarColor,
            mensagemSnackBar,
            snackbarIcon = 'mdi-close'
        ) {
            this.snackbarColor = snackbarColor;
            this.mensagemSnackBar = mensagemSnackBar;
            this.snackbarIcon = snackbarIcon;
            this.snackbarDialog = true;
        },
        formatDate(data) {
            return moment(data, 'MM-DD-YYYY').format('DD/MM/YYYY');
        },
        selecionarData(data) {
            this.menu2 = false;
            this.formatDatePtBr(data);
        },
        validarDataDigitada(isEnter = false, event = undefined) {
            const isDataValida = this.validarFormatoData(this.dataDigitavel);
            if (isDataValida) {
                if (isEnter && event) {
                    event.target.blur();
                    setTimeout(() => {
                        this.menu2 = false;
                    }, 150);
                }
                this.data = this.setDataDigitada(this.dataDigitavel);
                this.formatDatePtBr(this.data);
            } else {
                this.emitirSnackbar('error', 'Digite uma data válida');
            }
        },
        setDataDigitada(data) {
            if (!data) return null;
            const [day, month, year] = data.split('/');
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        },
        formatDatePtBr(data) {
            if (!data) return null;
            const [year, month, day] = data.split('-');
            this.dataApontamento = day + '/' + month + '/' + year;
            this.dataDigitavel = day + '/' + month + '/' + year;
            return `${day}/${month}/${year}`;
        },
        callMethod(method) {
            this[method]();
        },
        editarApontamento() {
            this.ativaBotaoCartorio();
            this.modalApontamento = true;
        },
        async buscaMunicipio() {
            this.loadingMunicipios = true;
            let tituloService = new TituloService(Vue.http, this.$store);
            await tituloService.getMunicipios().then(response => {
                const itensMunicipios = {};
                itensMunicipios.municipios = response.body;
                itensMunicipios.requestDate = moment();
                this.$store.commit('setItensMunicipios', itensMunicipios);
            });
            this.loadingMunicipios = false;
        },
        async ativaBotaoCartorio() {
            this.editarCartorio = true;
            this.desabilitaCartorios = true;
            this.novoProtocolo = this.titulo.apontamento_nu_protocolo;

            if (!this.titulo.apontamento_dt_protocolo) {
                this.data = getDataAtualDatePicker();
            } else {
                this.data = this.titulo.apontamento_dt_protocolo;
            }

            this.formatDatePtBr(this.data);

            if (!this.listaMunicipios.length) {
                await this.buscaMunicipio();
            }
            this.iconLateral = true;

            if (this.titulo.apontamento_cd_municipio) {
                this.valueMunicipios = lodash.cloneDeep(
                    this.titulo.apontamento_cd_municipio
                );
                await this.buscaCartorio();

                if (this.titulo.apontamento_nu_cartorio) {
                    this.valueCartorios = lodash.cloneDeep(
                        this.titulo.apontamento_nu_cartorio
                    );
                }
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.feedback {
    height: 210px;
    justify-content: center;
    display: flex;
    align-items: center;
}
</style>
