<template>
    <div class="dropdown" data-html2canvas-ignore>
       <button class="dropbtn" @click="showMenu = !showMenu">Ações <v-icon color="#04BDD7">mdi-chevron-down</v-icon></button>
       <div class="dropdown-content" v-show="showMenu">
            <a v-for="action in actions" :key="action.name" @click="action.disabled ? null : executeAction(action.method)" :class="{ disabled: action.disabled }">{{ action.name }}</a>
       </div>
    </div>
</template>

<script>
export default {
    props:{
        actions: {
            type: Array,
            required: true
        }
    },
    
    data() {
       return {
         showMenu: false,
       };
    },
    methods: {
        executeAction(action) {
            this.$emit('executeAction', action);
        },
    },
};
</script>

<style scoped>
.dropdown {
    position: relative;
    display: inline-block;
    font-weight: bold;
}

.dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: max-content;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    font-weight: normal;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}

.dropdown-content a.disabled {
    color: #ccc;
    cursor: not-allowed;
}

.dropdown:hover .dropdown-content {
    display: block;
}
</style>
