<template>
    <v-card class="pa-3">
        <v-row>
            <v-col>
                <h4 class="bold">Devedor</h4>
            </v-col>
            <v-col class="d-flex justify-end">
                <select-actions 
                    @executeAction="callMethod" 
                    :actions="[
                        {name: 'Alterar contatos', method: 'editContacts'},
                        {name: 'Alterar Endereço', method: 'editAddress', disabled: computedPermitirCorrecao && titulo.ds_revisao !== 'AP'},
                        {name: labelMailing, method: 'setMailing'}
                        ]"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pb-3" cols="4">
                <v-text-field
                    v-if="checarNomeDevedorCRA(titulo)"
                    outlined
                    dense
                    v-model="titulo.nm_devedor_cra"
                    label="Devedor Ajustado"
                    required
                    readonly
                    hide-details="auto"
                ></v-text-field>
                <v-text-field
                    v-else
                    outlined
                    dense
                    v-model="titulo.nm_devedor"
                    label="Devedor"
                    required
                    readonly
                    hide-details="auto"
                ></v-text-field>
            </v-col>
            <v-col class="pb-3" cols="2">
                <v-text-field
                    outlined
                    dense
                    :value="titulo.nu_identificacao_devedor"
                    v-mask="['XX.XXX.XXX/XXXX-##', '###.###.###-##']"
                    label="CNPJ/CPF"
                    readonly
                    hide-details="auto"
                ></v-text-field>
            </v-col>
            <v-col class="pb-3">
                <v-text-field
                    v-if="checarEnderecoDevedorCRA(titulo)"
                    outlined
                    dense
                    v-model="titulo.ds_endereco_devedor_cra"
                    label="Endereço Ajustado"
                    required
                    readonly
                    class="parcelas"
                    hide-details="auto"
                ></v-text-field>
                <v-text-field
                    v-else
                    outlined
                    dense
                    v-model="titulo.ds_endereco_devedor"
                    label="Endereço"
                    required
                    readonly
                    class="parcelas"
                    hide-details="auto"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col class="pb-3">
                <v-text-field
                outlined
                dense
                :value="titulo.ds_bairro_devedor"
                label="Bairro"
                hide-details="auto"
                readonly
                ></v-text-field>
            </v-col>    
            <v-col class="pb-3">
                <v-text-field
                outlined
                dense
                :value="titulo.nm_cidade_devedor"
                label="Cidade"
                readonly
                hide-details="auto"
                ></v-text-field>
            </v-col>
            <v-col class="pb-3">
                <v-text-field
                outlined
                dense
                :value="titulo.cd_cep_devedor"
                v-mask="'#####-###'"
                label="CEP"
                hide-details="auto"
                readonly
                ></v-text-field>
            </v-col>
            <v-col class="pb-3" cols="1">
                <v-text-field
                outlined
                dense
                :value="titulo.cd_uf_devedor"
                label="UF"
                readonly
                hide-details="auto"
                ></v-text-field>
            </v-col>
            <v-col class="pb-3">
                <v-text-field
                outlined
                dense
                :value="titulo.ds_email_devedor"
                label="E-mail do devedor"
                readonly
                hide-details="auto"
                ></v-text-field>
            </v-col>
            <v-col class="pb-3">
                <v-text-field
                outlined
                hide-details="auto"
                dense
                :value="titulo.nu_telefone_devedor"
                label="Telefone do devedor"
                readonly
                ></v-text-field>
            </v-col>
            
        </v-row>
        <v-dialog
            v-if="habilitaCorrecao"
            v-model="habilitaCorrecao"
            persistent
            transition="dialog-bottom-transition"
            max-width="900px"
        >
            <correcao-titulo
                @fecharModal="desabilitaDialogCorrecao"
                :detalhe_titulo="titulo"
                :open="1"
            ></correcao-titulo>
        </v-dialog>
        <Snackbar
            v-if="feedbackSnackbar"
            :mostrarSnackbar="feedbackSnackbar"
            :corSnackbar="feedbackSnackbarColor"
            :mensagemSnackbar="mensagemfeedbackSnackbar"
            @fecharSnackbar="feedbackSnackbar = false"
        />
    </v-card>
    
</template>
<script>
import SelectActions from '../atoms/SelectActions.vue';
import CorrecaoTitulo from '@/components/CorrecaoTitulo';
import TituloService from '@/services/TituloService';
import Vue from 'vue';
import moment from 'moment';
export default {
    name: 'CardDevedor',
    components: {
        SelectActions,
        CorrecaoTitulo
    },
    props: {
        titulo: {
            type: Object,
            required: true
        }
    },
    created() {
        moment.locale('pt-br');
    },
    data() {
        return {
            habilitaCorrecao: false,
            feedbackSnackbar: false,
            feedbackSnackbarColor: '',
            feedbackSnackbarTimeout: 4000,
            mensagemfeedbackSnackbar: '',
        };
    },
    computed: {
        labelMailing() {
            return this.titulo.st_email_callback === 0 ? 'Ativar Mailing' : 'Desativar Mailing';
        },
        computedPermitirCorrecao() {
            if (
                this.titulo.ds_revisao != null &&
                this.titulo.ds_revisao != '' &&
                this.titulo.cd_status == 220
            ) {
                return false;
            } else {
                return true;
            }
        },
    },
    methods:{
        snackbar(color, text) {
            this.feedbackSnackbarColor = color;
            this.mensagemfeedbackSnackbar = text;
            this.feedbackSnackbar = true;
            
        },
        setMailing(){
            const tituloService = new TituloService(Vue.http, this.$store);
            tituloService.desativarMailing(this.titulo).then(response => {
                if (response.status == 200) {
                    this.snackbar(
                        'success',
                        this.titulo.st_email_callback ? 'Mailing desativado com sucesso.' : 'Mailing ativado com sucesso.'
                    );
                    this.$emit('atualizaTitulo');
                }
            }).catch(() => {
                this.snackbar(
                    'error',
                    this.titulo.st_email_callback ? 'Erro ao desativar Mailing.' : 'Erro ao ativar Mailing.'
                );
            });

        },        
        desabilitaDialogCorrecao() {
            this.habilitaCorrecao = !this.habilitaCorrecao;
        },
        checarNomeDevedorCRA(titulo) {
            if (titulo.nm_devedor_cra && titulo.nm_devedor_cra !== '') {
                return true;
            }
            return false;
        },
        checarEnderecoDevedorCRA(titulo) {
            if (
                titulo.ds_endereco_devedor_cra &&
                titulo.ds_endereco_devedor_cra !== ''
            ) {
                return true;
            }
            return false;
        },
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY');
        },
        callMethod(method) {
            method === 'editContacts' && this.$emit('editContacts');
            method === 'editAddress' && (this.habilitaCorrecao = true);
            method === 'setMailing' && this.setMailing();
        }
    }
}
</script>
