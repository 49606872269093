<template>
    <v-card
        v-if="mostrarAnexoProtesto() && verificaPermissaoAnexo()"
        :loading="loadingAnexo"
        class="ma-3 px-3 pt-3 pb-6"
    >
        <v-container>
            <p class="title" ref="sessaoAnexo">
                <v-icon class="ma-2">mdi-information-outline</v-icon>
                Anexo Arquivo de Protesto 
            </p>
            <v-row
                v-if="anexoProtesto != null"
                class="mt-2 d-flex align-center"
            >
                <v-col cols="12" sm="4" md="4" class="py-0">
                    <v-text-field
                        v-if="!arquivosSelecionadosTitulo"
                        outlined
                        dense
                        v-model="anexoProtesto.ds_anexo_nome"
                        label="ID Anexo"
                        required
                        readonly
                        hide-details
                    ></v-text-field>
                    <div v-else>
                        <span> Voce tem certeza que deseja substituir o anexo? </span>
                        {{ formatarNomesArquivos() }}
                        <v-tooltip
                            v-if="
                                arquivosSelecionadosTitulo &&
                                    arquivosSelecionadosTitulo.length
                            "
                            top
                        >
                            <template
                                v-slot:activator="{
                                    on,
                                    attrs
                                }"
                            >
                                <v-icon
                                    class="ma-3"
                                    :color="
                                        tamanhoArquivosValido(
                                            arquivosSelecionadosTitulo
                                        )
                                            ? 'green'
                                            : 'red'
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{
                                        tamanhoArquivosValido(
                                            arquivosSelecionadosTitulo
                                        )
                                            ? 'done'
                                            : 'error'
                                    }}
                                </v-icon>
                            </template>
                            <span>
                                {{
                                    tamanhoArquivosValido(
                                        arquivosSelecionadosTitulo
                                    )
                                        ? 'Os arquivos selecionados estão dentro do tamanho máximo permitido de 5MB por Título'
                                        : 'A soma do tamanho dos arquivos não pode ultrapassar 5MB por Título'
                                }}
                            </span>
                        </v-tooltip>
                    </div>
                </v-col>
                <div v-if="!arquivosSelecionadosTitulo">
                    <v-btn
                        class="white--text ml-3"
                        @click="baixarArquivoProtesto"
                        :loading="loadingAnexo"
                        color="green"
                        width="120px"
                        >BAIXAR
                    </v-btn>
                    <v-btn
                        v-if="titulo.cd_status == 220"
                        class="white--text ml-3"
                        @click="document
                                .getElementById('pdfFileInputUpdate')
                                .click()"
                        :loading="loadingAnexo || loading"
                        color="blue"
                        width="auto"
                        >substituir
                        <v-icon class="mx-2">mdi-file-replace-outline</v-icon>
                    </v-btn>
                    <v-file-input
                        v-model="arquivosSelecionadosTitulo"
                        :accept="'application/pdf, application/zip'"
                        style="display: none"
                        hide-input
                        prepend-icon=""
                        multiple
                        id="pdfFileInputUpdate"
                        @change="$forceUpdate()"
                    ></v-file-input>
                </div>
                <div v-else>
                    <v-btn class="white--text ml-3"
                        @click="arquivosSelecionadosTitulo = null"
                        
                        color="red"
                        width="auto"
                        >cancelar
                        <v-icon class="ml-2">mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                        class="white--text ml-3"
                        @click="substituirAnexo()"
                        :loading="loadingAnexo || loading"
                        color="green"
                        width="auto"
                        >confirmar
                        <v-icon class="ml-2">mdi-check</v-icon>
                    </v-btn>

                </div>
            </v-row>
            <div v-else>
                <v-row class="ma-0">
                    <v-col
                        cols="6"
                        xs="6"
                        sm="6"
                        md="7"
                        lg="7"
                        class="font-weight-bold"
                    >
                        Arquivo(s) anexado(s)
                    </v-col>
                    <v-col
                        cols="6"
                        xs="6"
                        sm="6"
                        md="5"
                        lg="5"
                        class="font-weight-bold"
                    >
                    </v-col>
                </v-row>
                <v-row class="ma-0">
                    <v-col
                        cols="6"
                        xs="6"
                        sm="6"
                        md="7"
                        lg="7"
                        class="pa-3 d-flex justify-start align-center"
                    >
                        {{ formatarNomesArquivos() }}
                        <v-tooltip
                            v-if="
                                arquivosSelecionadosTitulo &&
                                    arquivosSelecionadosTitulo.length
                            "
                            top
                        >
                            <template
                                v-slot:activator="{
                                    on,
                                    attrs
                                }"
                            >
                                <v-icon
                                    class="ma-3"
                                    :color="
                                        tamanhoArquivosValido(
                                            arquivosSelecionadosTitulo
                                        )
                                            ? 'green'
                                            : 'red'
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{
                                        tamanhoArquivosValido(
                                            arquivosSelecionadosTitulo
                                        )
                                            ? 'done'
                                            : 'error'
                                    }}
                                </v-icon>
                            </template>
                            <span>
                                {{
                                    tamanhoArquivosValido(
                                        arquivosSelecionadosTitulo
                                    )
                                        ? 'Os arquivos selecionados estão dentro do tamanho máximo permitido de 5MB por Título'
                                        : 'A soma do tamanho dos arquivos não pode ultrapassar 5MB por Título'
                                }}
                            </span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-row class="ma-0">
                    <v-col>
                        <span v-if="!anexoProtesto" class="text-grey">
                            <strong>Arquivos aceitos:</strong> PDF ou ZIP com tamanho inferior à 4,5MB. Certifique-se que os arquivos não possuam carecteres especiais
                        </span>
                    </v-col>
                    <v-col
                        cols="4"
                        xs="4"
                        sm="4"
                        md="3"
                        lg="3"
                        class="pa-0 d-flex justify-end align-center"
                    >
                        <v-btn
                            class="white--text ml-3"
                            @click="
                                document
                                    .getElementById('pdfFileInput')
                                    .click()
                            "
                            color="green"
                            :disabled="anexoProtesto != null"
                            :loading="loadingAnexo || loading"
                            >SELECIONAR ANEXO(s)
                        </v-btn>
                        <v-btn
                            v-if="!pendenteSelecionarArquivos()"
                            class="white--text ml-3"
                            @click="anexarArquivoProtesto()"
                            color="green"
                            :loading="loadingAnexo || loading"
                            :disabled="
                                !tamanhoArquivosValido(
                                    arquivosSelecionadosTitulo
                                )
                            "
                            >ENVIAR
                        </v-btn>
                    </v-col>
                </v-row>
                <v-file-input
                    v-model="arquivosSelecionadosTitulo"
                    :accept="'application/pdf, application/zip'"
                    style="display: none"
                    hide-input
                    prepend-icon=""
                    multiple
                    id="pdfFileInput"
                    @change="$forceUpdate()"
                ></v-file-input>
            </div>
        </v-container>
    </v-card>
</template>

<script>
import Vue from 'vue';
import TituloService from '@/services/TituloService';
export default {
    name: 'CardAnexoProtesto',
    props:{
        titulo: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            arquivosSelecionadosTitulo: null,
            loadingAnexo: false,
            tamanhoMaximoSomaArquivos: 5242880, // 5MB
            loading: false,
            anexoProtesto: null,
        };
    },
    methods: {
        buscarAnexoProtesto() {
            this.loadingAnexo = true;
            let titulo = this.titulo;
            const tituloService = new TituloService(Vue.http, this.$store);
            tituloService
                .buscarAnexoTitulo(titulo, 'AN')
                .then(
                    response => {
                        console.log('buscar anexo de protesto ->', response);
                        if (Object.keys(response.body).length > 0) {
                            this.anexoProtesto = response.body;
                        } else {
                            this.anexoProtesto = null;
                        }
                    },
                    error => {
                        console.log(error);
                        this.emitirSnackbar(
                            'error',
                            'Erro ao buscar Anexo do Protesto'
                        );
                    }
                )
                .finally(() => {
                    this.loadingAnexo = false;
                });
        },
        async anexarArquivoProtesto() {
            if (this.mostrarAnexoProtesto()) {
                try {
                    this.loadingAnexo = true;
                    const objetoAnexo = await this.montarObjetoAnexoProtesto(
                        this.titulo.ds_nosso_numero,
                        this.arquivosSelecionadosTitulo
                    );

                    const mensagemRetorno = await this.tituloService.anexarArquivo(
                        objetoAnexo
                    );

                    this.emitirSnackbar('green', mensagemRetorno, null);
                    this.loadingAnexo = false;
                    this.exibirDetalhe();
                    this.buscarAnexoProtesto();
                } catch (e) {
                    console.log(e);
                    const mensagem = this.getMensagemRequest(
                        e.data,
                        'Erro ao anexar Arquivo de Protesto'
                    );
                    this.loadingAnexo = false;
                    this.emitirSnackbar('error', mensagem);
                }
            }
        },
        pendenteSelecionarArquivos() {
            if (
                !this.arquivosSelecionadosTitulo ||
                !this.arquivosSelecionadosTitulo.length
            ) {
                return true;
            }
            return false;
        },
        mostrarAnexoProtesto() {
            if(this.titulo.ds_revisao.includes('AP')){
                return true;
            }
            if (
                [220,105,106,103,121].includes(this.titulo.cd_status)&&
                this.titulo.id_anexo
            ) {
                return true;
            }
            return false;
        },
        verificaPermissaoAnexo() {
            if (this.$store.getters.isBasicUser 
                || this.$store.getters.isAdmin
                || this.$store.getters.isSuperUser) {
                return true;
            }
            return false;
        },
        formatarNomesArquivos() {
            if (this.arquivosSelecionadosTitulo?.length > 0) {
                const arquivos = this.arquivosSelecionadosTitulo.map(
                    arquivo => arquivo.name
                );
                return arquivos.join(', ');
            }
            return '--';
        },
        tamanhoArquivosValido(arquivos) {
            const tamanhoArquivos = arquivos.reduce(
                (acumulador, file) => acumulador + file.size,
                0
            );
            if (tamanhoArquivos > this.tamanhoMaximoSomaArquivos) {
                return false;
            }
            return true;
        },
        async baixarArquivoProtesto() {
            try {
                this.loadingAnexo = true;

                const arquivoAnexo = await this.tituloService.baixarAnexo(
                    this.titulo
                );

                const url = window.URL.createObjectURL(
                    new Blob([arquivoAnexo])
                );
                const link = document.createElement('a');
                let extensao = await this.getExtension(
                    this.anexoProtesto.ds_anexo_nome
                );
                const nomeDocumento = `${this.titulo.ds_nosso_numero}_anexo_protesto.${extensao}`;
                link.href = url;
                link.setAttribute('download', nomeDocumento);
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                console.log(e);
                const mensagem = this.getMensagemRequest(
                    e.data,
                    'Erro ao baixar Arquivo de Protesto'
                );
                this.emitirSnackbar('error', mensagem);
            } finally {
                this.loadingAnexo = false;
            }
        },
        async substituirAnexo(){
            console.log('cd_status --- st_envio',this.titulo)
            if(this.titulo.cd_status === 220 && this.titulo.st_envio == 0){
                try{
                    this.loadingAnexo = true;
                    const objetoAnexo = await this.montarObjetoAnexoProtesto(
                        this.titulo.ds_nosso_numero,
                        this.arquivosSelecionadosTitulo
                    );
                    const mensagemRetorno = await this.tituloService.substituirAnexo(
                        objetoAnexo
                    );
                    this.arquivosSelecionadosTitulo = null;
                    this.emitirSnackbar('green', mensagemRetorno, null);
                    this.loadingAnexo = false;
                    this.exibirDetalhe();
                    this.buscarAnexoProtesto();
    
                } catch (e) {
                    console.log(e);
                    const mensagem = this.getMensagemRequest(
                        e.data,
                        'Erro ao anexar Arquivo de Protesto'
                    );
                    this.loadingAnexo = false;
                    this.emitirSnackbar('error', mensagem);
                }
            }else if(this.titulo.st_envio != 0){
                this.emitirSnackbar('error', 'Este titulo ja foi enviado');
            }
        },
    },
    computed: {
    },
    mounted() {
        if (this.mostrarAnexoProtesto() && this.verificaPermissaoAnexo()) {
            this.buscarAnexoProtesto();
        }
    },
    // Other component options go here
};
</script>

<style scoped>
/* Your component's CSS styles go here */
</style>
