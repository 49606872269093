<template>
    <v-card class="pa-3 card-historico-emails">
        <v-row>
            <v-col>
                <h4 class="bold">Historico de E-mails</h4>
            </v-col>
            <v-col class="d-flex justify-end">
                <v-btn 
                    v-if="listaEnvios.length"
                    :loading="loadingTituloImpressao"
                    @click="historicoImpressao()">
                    <v-icon>mdi-printer</v-icon>
                    Imprimir
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="listaEnvios"
                    :loading="loading"
                    :server-items-length="listaEnvios.length"
                    loading-text="Buscando envios..."
                    item-key="comarcas"
                    class="elevation-1"
                    no-data-text="Nenhum E-mail Enviado"
                    :footer-props="{
                        itemsPerPageOptions: [20, 100, 200, -1],
                    }"
                >
                    <template v-slot:[`item.dt_envio`]="{ item }">
                        <span>{{ formatDate(item.dt_envio) }}</span>
                    </template>

                    <template v-slot:[`item.dt_abertura`]="{ item }">
                        <span>{{ formatDate(item.dt_abertura) }}</span>
                    </template>

                    <template v-slot:[`item.dt_clique`]="{ item }">
                        <span>{{ formatDate(item.dt_clique) }}</span>
                    </template>

                    <template v-slot:[`item.dt_entregue`]="{ item }">
                        <span>{{ formatDate(item.dt_entregue) }}</span>
                    </template>

                    <template v-slot:[`item.cd_status`]="{ item }">
                        <span v-if="item.cd_status == '102'">
                            <p>Protestado</p>
                        </span>
                        <span v-if="item.cd_status == '225'">
                            <p>Apontado</p>
                        </span>
                        <span v-if="item.cd_status == '241'">
                            <p>Enviado a Cancelamento</p>
                        </span>
                        <span v-if="item.cd_status == '251'">
                            <p>Enviado a Anuência</p>
                        </span>
                        <span v-if="item.cd_status == '252'">
                            <p>Anuência Programada</p>
                        </span>
                    </template>

                    <template v-slot:[`item.st_ativo`]="{ item }">
                        <v-icon
                            :style="{
                                color: item.st_ativo != '' ? 'green' : 'red',
                            }"
                        >
                            {{ getBolean(item.st_ativo) }}</v-icon
                        >
                    </template>

                    <template v-slot:[`item.is_admin`]="{ item }">
                        <v-icon
                            :style="{
                                color: item.is_admin != '' ? 'green' : 'red',
                            }"
                        >
                            {{ getBolean(item.is_admin) }}</v-icon
                        >
                    </template>

                    <template v-slot:[`item.tracking`]="{ item }">
                        <span
                            v-if="item.tracking == '2'"
                            class="d-flex flex-row"
                        >
                            <v-tooltip bottom color="success">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        elevation="0"
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                        fab
                                        ><v-icon class="ma-2 pa-2 blue--text"
                                            >mdi-check-all</v-icon
                                        ></v-btn
                                    >
                                </template>
                                <span
                                    >E-mail visualizado pelo destinatário</span
                                >
                            </v-tooltip>
                            <v-tooltip bottom color="success">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        elevation="0"
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                        fab
                                    >
                                        <v-icon class="ma-2 pa-2"
                                            >mdi-cursor-default-click</v-icon
                                        >
                                    </v-btn>
                                </template>
                                <span>Essa mensagem recebeu cliques</span>
                            </v-tooltip>
                        </span>
                        <span v-if="item.tracking == '1'">
                            <v-tooltip bottom color="success">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        x-small
                                        fab
                                        v-bind="attrs"
                                        v-on="on"
                                        elevation="0"
                                    >
                                        <v-icon class="ma-2 pa-2 blue--text"
                                            >mdi-check-all</v-icon
                                        >
                                    </v-btn>
                                </template>
                                <span
                                    >E-mail visualizado pelo destinatário</span
                                >
                            </v-tooltip>
                        </span>
                        <span v-if="item.tracking == '0'">
                            <v-tooltip bottom color="success">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        x-small
                                        fab
                                        v-bind="attrs"
                                        v-on="on"
                                        elevation="0"
                                    >
                                        <v-icon class="ma-2 pa-2 grey--text"
                                            >mdi-check</v-icon
                                        ></v-btn
                                    ></template
                                >
                                <span>E-mail Entregue ao destinatário</span>
                            </v-tooltip>
                        </span>
                        <span v-if="item.tracking == null">
                            <v-tooltip bottom color="grey">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        elevation="0"
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                        fab
                                    >
                                        <v-icon class="ma-2 pa-2"
                                            >mdi-dots-horizontal</v-icon
                                        >
                                    </v-btn>
                                </template>
                                <span>E-mail enviado</span>
                            </v-tooltip>
                        </span>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <span v-if="item.ds_conteudo == undefined">
                            <v-tooltip bottom color="grey">
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        elevation="0"
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                        fab
                                        :style="{ color: 'gray' }"
                                    >
                                        <v-icon class="ma-2 pa-2"
                                            >mdi-eye-outline</v-icon
                                        >
                                    </v-btn>                                    
                                </template>
                                <span>
                                    Pré visualização não disponivel
                                </span>
                            </v-tooltip>
                        </span>
                        <span v-else>
                            <v-btn
                                elevation="0"
                                @click="exibirDetalhe(item)"
                                x-small
                                fab
                                :style="{ color: 'black' }"
                            >
                                <v-icon class="ma-2 pa-2"
                                    >mdi-eye-outline</v-icon
                                >
                            </v-btn>
                            
                        </span>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-dialog
            v-model="showTemplateDialog"
            transition="dialog-bottom-transition"
            max-width="1000px"
        >
            <component
                v-if="selectedEmail"
                :is="renderTemplate()"
                :usuarioPartner="usuarioPartner"
                :nomeDevedor="titulo.nm_devedor"
                :nomeExibicaoApresentante="NomeExibicaoApresentante"
                @fecharModal="showTemplateDialog = $event"
            />
        </v-dialog>
        <v-dialog
            v-model="showEmailDialog"
            transition="dialog-bottom-transition"
            max-width="1024px"
        >
            <span v-html="emailContent"></span>
        </v-dialog>
    </v-card>
</template>
<script>
import moment from 'moment';
import Template220 from '@/components/Template220';
import Template225 from '@/components/Template225';
import Template102 from '@/components/Template102';
import Template102Lembrete from '@/components/Template102Lembrete';
import Template240 from '@/components/Template240';
import Template250 from '@/components/Template250';
import Template250SP from '@/components/Template250SP';
import Template252 from '@/components/Template252';
import TituloService from '@/services/TituloService';
import Vue from 'vue';

export default {
    name: 'CardMensageria',
    props: {
        listaEnvios: {
            type: Array,
            required: true
        },
        titulo: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            headers: [
                {
                    text: 'E-mail Destinatário',
                    sortable: false,
                    value: 'ds_email_devedor'
                },
                {
                    text: 'Data de envio',
                    sortable: false,
                    value: 'dt_envio'
                },
                {
                    text: 'Entrega',
                    sortable: false,
                    value: 'dt_entregue'
                },
                {
                    text: 'Visualização',
                    sortable: false,
                    value: 'dt_abertura'
                },
                {
                    text: 'Clique',
                    sortable: false,
                    value: 'dt_clique'
                },
                {
                    text: 'Acompanhamento',
                    sortable: false,
                    value: 'tracking'
                },
                {
                    text: 'Status',
                    sortable: false,
                    value: 'cd_status'
                },
                { 
                    text: 'Visualizar', 
                    sortable: false, 
                    value: 'action' 
                }
            ],
            loading: false,
            showTemplateDialog: false,
            showEmailDialog: false,
            emailContent: '',
            selectedEmail: {},
            loadingTituloImpressao: false,
        }
    },
    computed:{
        usuarioPartner(){
            return !!this.$store.getters.isPartner
        },
        NomeExibicaoApresentante(){
            return this.$store.getters.NomeExibicaoApresentante || this.titulo.nm_sacador
        }
        
    },
    methods:{
        historicoImpressao() {
            console.log( 'historicoImpressao', this.listaEnvios && this.listaEnvios.length > 0)
            if (this.listaEnvios && this.listaEnvios.length > 0) {
                let tituloService = new TituloService(Vue.http, this.$store);
                this.loadingTituloImpressao = true;
                tituloService
                    .historicoImpressaoComponent({cd_apresentante: this.titulo.cd_apresentante, ds_nosso_numero: this.titulo.ds_nosso_numero})
                    .then(response => {
                        const url = window.URL.createObjectURL(
                            new Blob([response.data])
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'histórico-Titulo.pdf');
                        document.body.appendChild(link);
                        link.click();
                        this.loadingTituloImpressao = false;
                    })
                    .catch(() => {
                        this.loadingTituloImpressao = false;
                    });
            } else {
                // this.snackbarDetalheTitulo(
                //     true,
                //     'error',
                //     'Não há registros de histórico de e-mails para este título.',
                //     'mdi-information-outline'
                // );
            }
        },
        formatDate(item) {
            let dataFormatada;
            if (item) {
                dataFormatada = moment(item).format('DD/MM/YYYY');
            } else {
                dataFormatada = '--';
            }
            return dataFormatada;
        },
        exibirDetalhe(item) {
            this.showEmailDialog = true;
            this.emailContent = decodeURIComponent(escape(atob(item.ds_conteudo)));
        },
        renderTemplate(){
            if(this.selectedEmail.cd_status == 220){
                return Template220
            }
            if(this.selectedEmail.cd_status == 225){
                return Template225
            }
            if(this.selectedEmail.cd_status == 102){
                if(this.template102Lembrete){
                    return Template102Lembrete
                }
                return Template102
            }
            if(this.selectedEmail.cd_status == 240 || this.selectedEmail.cd_status == 241){
                return Template240
            }
            if(this.selectedEmail.cd_status == 250 || this.selectedEmail.cd_status == 251){
                if(this.selectedEmail.cd_uf_devedor === 'SP'){
                    return Template250SP
                }
                return Template250
            }
            if(this.selectedEmail.cd_status == 252){
                return Template252
            }
            
        },
    }
}

</script>
<style lang="scss">
.card-historico-emails {
    .v-data-table {
        .v-data-footer {
            display: none !important;
        }
    }
}
</style>