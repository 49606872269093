<template>
    <v-container class="bg-view">
        <v-row align="center">
            <v-col>
                <v-btn icon @click="$router.go(-1)">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <span>Voltar</span>
            </v-col>
        </v-row>
        <v-card v-if="printEffect == 'printEffect'" class="d-flex justify-center">
            <v-progress-circular
                class="ma-12"
                indeterminate
                size="150"
                color="primary"
                >Imprimindo...</v-progress-circular
            >
        </v-card>
        <v-card :class="printEffect" v-if="titulo.detalhe_titulo && !printEffect" class="pa-3 mt-3" id="page-layout" >
            <v-row id="container-logo" justify="center" style="display:none;">
                <v-col align="center">
                    <img
                        :src="logo"
                        alt="logo-print"
                        crossorigin="anonymous"
                        width="250px"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <header-page-v2 
                        :title="'Detalhes de titulo'"
                        :problemaMensageria="problemaMensageria"
                        @imprimirTituloPDF="imprimirTituloPDF"
                        :titulo="titulo.detalhe_titulo"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="d-flex justify-end" data-html2canvas-ignore>
                    <v-btn 
                        :dark="!computedPermitirAnuencia" 
                        color="#305AF0" class="mr-2" 
                        :disabled="computedPermitirAnuencia || isUserReadonly" 
                        @click="solicitaAnuencia(titulo.detalhe_titulo)">
                            <v-icon>mdi-check-circle</v-icon> Anuência
                    </v-btn>
                    <v-btn 
                        :dark="!computedPermitirCancelamento" 
                        color="#FF4163" 
                        class="mr-2" 
                        :disabled="computedPermitirCancelamento || isUserReadonly" 
                        @click="solicitaCancelamento()">
                            <v-icon>mdi-close-circle-outline</v-icon> Cancelamento
                    </v-btn>
                    <v-btn 
                        :dark="!computedPermitirDesistencia" 
                        :disabled="computedPermitirDesistencia || isUserReadonly" 
                        @click="solicitaDesistencia()"
                        color="#FF6E41">
                        <v-icon>mdi-close-thick</v-icon> Desistência
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <card-informacoes-titulo :titulo="titulo.detalhe_titulo" 
                    @alterarStatus="confirmarAlterarStatus"
                    @reiniciarEnvio="confirmarReenvio"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <CardDevedor @atualizaTitulo="atualizarPagina()" @editContacts="setAlterarContatosDevedor" :titulo="titulo.detalhe_titulo"/>
                </v-col>
            </v-row>
            <v-row v-if="titulo.detalhe_titulo.nu_quantidade_devedores > 1">
                <v-col>
                    <CardCoodevedores                        
                        :titulo="titulo.detalhe_titulo"                        
                        @atualizarTitulo="atualizarPagina()"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="5" class="align-self-stretch">
                    <CardHistoricoStatus :historico="titulo.historico_titulo" @atualizaTitulo="atualizarPagina()"/>
                </v-col>
                <v-col>
                    <CardApontamento @atualizaTitulo="atualizarPagina()" :titulo="titulo.detalhe_titulo"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pa-0">
                    <CardAnexoProtesto :titulo="titulo.detalhe_titulo" />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <CardSacadorCedenteApresentante :titulo="titulo.detalhe_titulo"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="align-self-stretch">
                    <CardMensageria data-html2canvas-ignore :titulo="titulo.detalhe_titulo" @erroMensageria="erroMensageria"/>
                </v-col>
                <!-- <v-col>
                    <CardInstrumentoProtesto :titulo="titulo.detalhe_titulo"/>
                </v-col> -->
            </v-row>
            <v-row>
                <v-col>
                    <CardHistoricoEmails :titulo="titulo.detalhe_titulo" :listaEnvios="historicoEmails"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <CardComentariosTitulo data-html2canvas-ignore :comentarios="comentarios" :id_titulo="parseInt(id_titulo)" @atualizarComentarios="getComentarios()"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <CardDownloadEnvios 
                        :titulo="titulo.detalhe_titulo"
                        @feedbackError="(msg) => setFeedbackError(msg)"
                    />
                </v-col>
            </v-row>
        </v-card>
        <!-- MODAIS -->
        <v-dialog v-model="modalInformativoPreAnuencia" max-width="800px">
            <v-card class="pa-3">
                <v-card-text class="ma-2 pa-2 font-weight-bold title"
                    >Deseja programar o envio da anuência?</v-card-text
                >
                <v-card-text class="ma-2 pa-2">
                    O título/dívida ainda está com status “Apontado”.
                    Portanto, o envio da anuência será programado para ser
                    feito automaticamente logo após a formalização do
                    protesto, ou seja, depois da intimação do devedor
                    (presencial, carta com AR ou por edital) e da lavratura
                    do instrumento de protesto.
                </v-card-text>
                <v-card-text class="ma-2 pa-2">
                    O Apresentante/Credor confirma que o devedor está ciente
                    que:
                </v-card-text>
                <v-card-text class="ma-2 pa-2">
                    - Como condição da negociação/pagamento da dívida
                    enviada a protesto, caberá ao devedor pagar os
                    emolumentos diretamente ao cartório depois que o
                    procedimento de protesto for concluído;
                </v-card-text>
                <v-card-text class="ma-2 pa-2">
                    - Ele(a) poderá receber uma intimação do cartório sobre
                    o protesto;
                </v-card-text>
                <v-card-text class="ma-2 pa-2">
                    - A anuência eletrônica está programada e será enviada
                    ao cartório apenas após a formalização do protesto.
                </v-card-text>
                <v-card-text class="ma-2 pa-2">
                    A Recuperi irá enviar e-mail(s) ao devedor no endereço
                    de e-mail cadastrado pelo Apresentante/Credor com
                    orientações sobre o procedimento de protesto, a
                    programação do envio da anuência e pagamento de
                    emolumentos.
                </v-card-text>
                <v-checkbox
                    class="ma-2 pa-2"
                    v-model="checkbox"
                    data-cy="anuenciaTermosCheckbox"
                    :label="
                        `O Apresentante/Credor autoriza a Recuperi a programar o envio de anuência.`
                    "
                ></v-checkbox>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="green white--text"
                        :disabled="!checkbox"
                        @click="confirmaCancelamento"
                        data-cy="confirmarPreAnuencia"
                        >Confirmar
                    </v-btn>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="negaCancelamento"
                        >Cancelar</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogConfirmationGeneric"
            max-width="400"
            @click:outside="negaCancelamento"
        >
            <v-card>
                <v-card-title class="headline primary--text">{{
                    genericDialog.title
                }}</v-card-title>

                <v-card-text>
                   {{ genericDialog.text }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    
                    <v-btn
                        color="green darken-1"
                        text
                        @click="confirmaDialogGeneric"
                        data-cy="confirmaCancelamento"
                        >{{ genericDialog.labelConfirmBtn }}
                    </v-btn>

                    <v-btn
                        color="red darken-1"
                        text
                        
                        @click="cancelDialogGeneric"
                        >{{genericDialog.labelCancelBtn}}</v-btn
                    >

                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogConfirmation"
            max-width="400"
            @click:outside="negaCancelamento"
        >
            <v-card>
                <v-card-title class="headline primary--text">{{
                    tipo_cancelamento.substring(2)
                }}</v-card-title>

                <v-card-text v-if="titulo.detalhe_titulo" v-show="!dialogResponse">
                    Deseja realmente realizar {{ tipo_cancelamento }} do
                    título {{ titulo.detalhe_titulo.ds_nosso_numero }}?
                    {{ dialogExtra }}
                </v-card-text>
                <v-card-text v-show="dialogResponse">{{
                    dialogText
                }}</v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    
                    <v-btn
                        color="green darken-1"
                        text
                        v-show="!dialogResponse"
                        @click="confirmaCancelamento"
                        data-cy="confirmaCancelamento"
                        >{{ labelConfirm ? labelConfirm : 'Sim' }}
                    </v-btn>

                    <v-btn
                        color="red darken-1"
                        text
                        v-show="!dialogResponse"
                        @click="negaCancelamento"
                        >Não</v-btn
                    >

                    <v-btn
                        color="green darken-1"
                        text
                        v-show="dialogResponse"
                        data-cy="confirmaResponse"
                        @click="fechaCancelamento"
                        >Ok!
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogInfoDesistencia"
            persistent
            transition="dialog-bottom-transition"
            max-width="1000px"
            class="ma-3 pa-3"
        >
            <v-card>
                <v-row>
                    <v-col md="7" lg="7" class="pa-5 ma-4">
                        <v-card-title class="font-weight-black">
                            <v-icon size="50" class="ma-2"
                                >mdi-alert-circle-outline</v-icon
                            >Atenção
                        </v-card-title>
                        <v-card-title
                            class="
                                red--text
                                display-1
                                font-weight-black
                            "
                        >
                            A desistência foi solicitada após o
                            prazo legal de 3 dias da data de envio
                            do título para protesto.
                        </v-card-title>
                        <v-card-text class="">
                            Caso o cartório responsável não aceite o
                            pedido de desistência (de acordo com a
                            Lei nº 9.492/1997), a Recuperi enviará
                            automaticamente o comando de
                            cancelamento assim que o devedor for
                            efetivamente protestado.
                        </v-card-text>
                        <v-card-text class="">
                            Importante: O comando de desistência
                            gera custos de responsabilidade do
                            apresentante.
                        </v-card-text>
                    </v-col>
                    <v-col md="4" lg="4" class="pa-5 ma-4">
                        <v-card-text
                            >Saiba mais:
                            <a
                                href="http://www.planalto.gov.br/ccivil_03/leis/l9492.htm"
                                target="_blank"
                            >
                                http://www.planalto.gov.br/ccivil_03/leis/l9492.htm</a
                            >
                        </v-card-text>
                        <v-card-text>
                            <v-icon class="ma-2"
                                >mdi-arrow-right</v-icon
                            >Em caso de dúvida, por favor entre em
                            contato com suporte@recuperi.com.br
                        </v-card-text>
                        <v-btn
                            color="green white--text"
                            class="ma-3 pa-3"
                            @click="confirmaCancelamentoDesistencia"
                            >Ok, desejo continuar</v-btn
                        >

                        <v-btn
                            color="red white--text"
                            class="ma-3 pa-3"
                            @click="dialogInfoDesistencia = true"
                            >Fechar</v-btn
                        >
                    </v-col>
                </v-row>
                <div class="ma-3 pa-3"></div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="modalAlterarContatosDevedor" max-width="500">
            <v-card>
                <v-card-text>
                    <v-card-title class="headline primary--text pl-0"
                        >Alterar contatos do devedor</v-card-title
                    >
                    <v-spacer></v-spacer>
                    <v-row class="mt-3">
                        <v-col>
                            <v-text-field
                                outlined
                                dense
                                v-model="novosContatosdevedor.email"
                                label="E-mail do devedor"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-mask="'(##) #####-####'"
                                outlined
                                dense
                                v-model="novosContatosdevedor.celular"
                                label="Telefone do devedor"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        dense
                        color="red white--text"
                        text
                        @click="modalAlterarContatosDevedor = false"
                        >Cancelar</v-btn
                    >
                    <v-btn
                        :disabled="!validateContatosDevedor"
                        dense
                        color="green darken-1"
                        text
                        @click="alterarContatoDevedor()"
                        >Salvar</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Snackbar
            data-html2canvas-ignore
            v-if="feedbackSnackbar"
            :mostrarSnackbar="feedbackSnackbar"
            :corSnackbar="feedbackSnackbarColor"
            :mensagemSnackbar="mensagemfeedbackSnackbar"
            @fecharSnackbar="feedbackSnackbar = false"
        />
    </v-container>
</template>
<script>
import Vue from 'vue'
import HeaderPageV2 from '@/components/atoms/HeaderPageV2.vue'
import CardInformacoesTitulo from '@/components/organisms/CardInformacoesTitulo.vue'
import TituloService from '@/services/TituloService'
import CardApontamento from '../components/organisms/CardApontamento.vue'
import CardDevedor from '../components/organisms/CardDevedor.vue'
import CardSacadorCedenteApresentante from '../components/organisms/CardSacadorCedenteApresentante.vue'
import CardHistoricoStatus from '../components/organisms/CardHistoricoStatus.vue'
import CardMensageria from '../components/organisms/CardMensageria.vue'
// import CardInstrumentoProtesto from '../components/organisms/CardInstrumentoProtesto.vue'
import CardHistoricoEmails from '../components/organisms/CardHistoricoEmails.vue'
import MensageriaService from '@/services/MensageriaService';
import CardComentariosTitulo from '../components/organisms/CardComentariosTitulo.vue';
import CancelamentoService from '@/services/cancelamentoService';
import CardCoodevedores from '@/components/organisms/CardCoodevedores.vue';
import CardDownloadEnvios from '@/components/organisms/CardDownloadEnvios.vue'
import CardAnexoProtesto from '@/components/organisms/CardAnexoProtesto.vue'
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

    export default {
        name: 'DetalheTitulo',
        components: {
            HeaderPageV2,
            CardInformacoesTitulo,
            CardApontamento,
            CardDevedor,
            CardSacadorCedenteApresentante,
            CardHistoricoStatus,
            CardMensageria,
            // CardInstrumentoProtesto,
            CardHistoricoEmails,
            CardComentariosTitulo,
            CardCoodevedores,
            CardDownloadEnvios,
            CardAnexoProtesto
        },
        data(){
            return {
                printEffect: '',
                genericDialog: {
                    title: '',
                    text: '',
                    labelConfirmBtn: '',
                    labelCancelBtn: ''
                },
                feedbackSnackbarTimeout: 5000,
                feedbackSnackbarColor: '',
                mensagemfeedbackSnackbar: '',
                feedbackSnackbar: false,
                dialogConfirmationGeneric: false,
                titulo: {},
                id_titulo: null,
                cd_apresentante: null,
                historicoEmails: [],
                tituloService: new TituloService(Vue.http, this.$store),
                emailService: new MensageriaService(Vue.http, this.$store),
                comentarios:[],
                problemaMensageria: false,
                modalInformativoPreAnuencia: false,
                dialogConfirmation: false,
                tipo_path: '',
                irregularidadeTipo:'',
                statusTitulo: '',
                tipo_cancelamento: '',
                checkbox: false,
                dialogResponse: false,
                dialogText: '',
                dialogExtra: null,
                labelConfirm: null,
                loadingCancelamento: false,
                ultimoTitulo: null,
                dt_ontem_desistencia: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                dialogInfoDesistencia: false,     
                modalAlterarContatosDevedor: false,    
                novosContatosdevedor: {
                    email: '',
                    celular: ''
                },
                logo: '',
            }
        },
        created() {
            this.id_titulo = this.$route.query.id_titulo;
            this.cd_apresentante = this.$route.query.cd_apresentante;
            this.getTitulo();
            this.getComentarios();
            
        },
        computed:{
            userProfile() {
                return this.$store.getters.user;
            },
            validateContatosDevedor(){
                const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
                const phoneRegex = /^\d{11}$/;
                return regex.test(this.novosContatosdevedor.email) && phoneRegex.test(this.novosContatosdevedor.celular.replace(/\D/g, ''));
            },
            isUserReadonly() {
                return this.$store.getters.isUserReadonly;
            },
            computedPermitirAnuencia() {
                if ([123, 109, 102, 221, 225].includes(this.titulo.detalhe_titulo.cd_status)) {
                    if (
                        this.titulo.historico_titulo[0] &&
                        [250].includes(this.titulo.historico_titulo[0].cd_status)
                    ) {
                        // se já existir um histórico 250, não permitir nova anuência
                        return true;
                    }
                    if (!this.titulo.historico_titulo[0]) {
                        // se não há histórico, permitir anuência
                        return false;
                    } else return false;
                } else return true;
            },
            computedPermitirCancelamento() {
                if (
                    [123, 109, 102, 250, 251, 144].includes(this.titulo.detalhe_titulo.cd_status)
                ) {
                    if (
                        this.titulo.historico_titulo[0] &&
                        [250].includes(this.titulo.historico_titulo[0].cd_status)
                    ) {
                        return true;
                    }
                    if (!this.titulo.historico_titulo[0]) {
                        // se não há histórico, permitir comando
                        return false;
                    } else return false;
                } else return true;
            },
            computedPermitirDesistencia() {
                if (
                    [220, 221, 225].includes(this.titulo.detalhe_titulo.cd_status) ||
                    this.titulo.detalhe_titulo.permite_desistencia
                ) {
                    if (
                        this.titulo.historico_titulo[0] &&
                        [250].includes(this.titulo.historico_titulo[0].cd_status)
                    ) {
                        return true;
                    }
                    if (!this.titulo.historico_titulo[0]) {
                        // se não há histórico, permitir comando
                        return false;
                    } else return false;
                } else return true;
            },
        },
        mounted() {
            this.logo = this.userProfile && this.userProfile.ds_identidade_visual ? this.userProfile.ds_identidade_visual.url_logo : require('@/assets/logo_recuperi_azul_escura.png');
        },
        methods: {
            imprimirTituloPDF() {
                const iframeExistente = document.getElementById('page-layout');
                this.printEffect = 'printEffect';
                let datePrint = moment().format('DD/MM/YYYY HH:mm:ss');
                if (iframeExistente) {
                    const containerLogo = iframeExistente.querySelector('#container-logo');
                    containerLogo.style.display = 'block';
                    if (containerLogo) {
                        html2canvas(iframeExistente, { allowTaint: true, useCORS: true, scale: 1.8 }).then(canvas => {
                            if (canvas.width > 0 && canvas.height > 0) {
                                const pdf = new jsPDF('p', 'pt', 'a4');
                                containerLogo.style.display = 'none';
                                // Adicione o canvas do conteúdo
                                pdf.addImage(canvas.toDataURL('image/jpeg'), 'JPEG', 20, 0, 550, 0);
                                // Salve o PDF com o título
                                pdf.setFontSize(8);
                                pdf.setTextColor(128, 128, 128);
                                pdf.text('Data de impressão: ' + datePrint, 20, pdf.internal.pageSize.height- 20);
                                pdf.save('titulo-'+this.titulo.detalhe_titulo.ds_nosso_numero+'.pdf');
                                this.printEffect = '';
                            } else {
                                console.error('O canvas gerado tem dimensões inválidas.');
                                this.printEffect = '';
                            }
                        });
                    } else {
                        console.error('Elemento com ID "container-logo" não encontrado no iframe.');
                        this.printEffect = '';
                    }
                } else {
                    this.printEffect = '';
                    console.error('Iframe com ID "page-layout" não encontrado.');
                }
            },

            atualizarPagina(){
                this.getTitulo();
                this.getComentarios();
            },
            alterarContatoDevedor(){
                this.modalAlterarContatosDevedor = false;
                this.novosContatosdevedor.celular = this.novosContatosdevedor.celular.replace(/\D/g, '');
                let payload = {
                    id_titulo: this.titulo.detalhe_titulo.id_titulo,
                    cd_apresentante: this.titulo.detalhe_titulo.cd_apresentante,
                    ds_email_devedor: this.novosContatosdevedor.email,
                    nu_telefone_devedor: this.novosContatosdevedor.celular,
                    nm_devedor_cra: "",
                    ds_endereco_devedor_cra: "",
                }
                this.tituloService.trocarContatoDevedor(payload)
                    .then(async (response) => {
                        console.log(response)
                        if (response.status == 200) {
                            this.setFeedbackSnackbar(
                                'success',
                                'Contato do devedor alterado com sucesso.'
                            );
                            this.getTitulo();
                            this.getComentarios();
                        }
                    })
                    .catch(() => {
                        this.setFeedbackSnackbar(
                            'error',
                            'Erro ao alterar contato do devedor.'
                        );
                    });

            },
            setAlterarContatosDevedor(){
                this.novosContatosdevedor.email = this.titulo.detalhe_titulo.ds_email_devedor;
                this.novosContatosdevedor.celular = this.titulo.detalhe_titulo.nu_telefone_devedor;
                this.modalAlterarContatosDevedor = true;
            },
            recolocarEmFila(titulo) {
                // let tituloService = new TituloService(Vue.http, this.$store);
                this.tituloService.recolocarEmFilaDeEnvio(titulo).then(
                    response => {
                        if (response.status == 200) {
                            this.setFeedbackSnackbar(
                                'success',
                                'Título atualizado com sucesso: Situação do envio: 0',
                                // 'mdi-text-box-check-outline'
                            );
                            this.desabilitaBotaoFila = true;
                        }
                    },
                    error => {
                        if (error.status == 400) {
                            this.snackbarDetalheTitulo(
                                'error',
                                'Alteração não permitida: Erro 400',
                                // 'mdi-alert-circle-outline'
                            );
                        }
                        console.log(error.body);
                    }
                );
            },
            cancelDialogGeneric() {
                this.dialogConfirmationGeneric = false;
                this.genericDialog = {
                    title: '',
                    text: '',
                    labelConfirmBtn: '',
                    labelCancelBtn: ''
                };
            },
            confirmaCancelamentoDesistencia() {
                this.solicitaCancelamentoPadrao();
                this.dialogInfoDesistencia = false;
            },
            solicitaDesistencia() {
                if (this.titulo.detalhe_titulo.dt_atualizacao < this.dt_ontem_desistencia) {
                    this.dialogInfoDesistencia = true;
                    this.tipo_path = 'desistencia';
                    this.tipo_cancelamento = 'a Desistência';
                } else {
                    this.tipo_path = 'desistencia';
                    this.tipo_cancelamento = 'a Desistência';
                    this.dialogConfirmation = true;
                    this.dialogExtra =
                        'O comando de desistência gera custos de responsabilidade do apresentante.';
                    this.labelConfirm = 'Sim, estou ciente';
                }
            },
            confirmaDialogGeneric() {
                this.dialogConfirmationGeneric = false;
                this.genericDialog = {
                    title: '',
                    text: '',
                    labelConfirmBtn: '',
                    labelCancelBtn: ''
                };
                if(this.tipo_path == 'alterar-status'){
                    this.alterarStatus()
                }else if(this.tipo_path == 'reenvio'){
                    this.recolocarEmFila();
                }

            },
            confirmaCancelamento() {
                this.solicitaCancelamentoPadrao();
            },
            fechaCancelamento() {
                this.dialogConfirmation = false;
                this.dialogResponse = false;
                this.getTitulo();
                this.getComentarios();
            },
            negaCancelamento() {
                this.dialogConfirmation = false;
                this.modalInformativoPreAnuencia = false;
                this.dialogExtra = null;
                this.labelConfirm = null;
            },
            solicitaCancelamentoPadrao() {
                this.dialogConfirmation = false;
                this.loadingCancelamento = true;
                let cd_apresentante = this.titulo.detalhe_titulo.cd_apresentante;
                if (this.titulo.detalhe_titulo.cd_comprador) {
                    cd_apresentante = this.titulo.detalhe_titulo.cd_comprador;
                }
                return new Promise((resolve, reject) => {
                    try {
                        let service = new CancelamentoService(
                            Vue.http,
                            this.$store
                        );
                        service
                            .cancelamentoTitulo(
                                this.titulo.detalhe_titulo.id_titulo,
                                this.tipo_path,
                                cd_apresentante
                            )
                            .then(
                                response => {
                                    if (response.status == 200) {
                                        this.loadingCancelamento = false;
                                        this.modalInformativoPreAnuencia = false;
                                        this.dialogText =
                                            'Título ' +
                                            this.titulo.detalhe_titulo.id_titulo +
                                            ' enviado ' +
                                            this.tipo_cancelamento +
                                            '!';
                                        this.dialogConfirmation = true;
                                        this.dialogResponse = true;                                        
                                        this.getTitulo();
                                        this.getComentarios();                                        
                                    }
                                },
                                error => {
                                    let message = '';
                                    if (error?.body?.msg) {
                                        message = error.body.msg;
                                    }
                                    this.loadingCancelamento = false;
                                    this.dialogText =
                                        'Erro ao enviar ' +
                                        this.tipo_cancelamento +
                                        'do título ' +
                                        this.titulo.detalhe_titulo.id_titulo +
                                        ': ' +
                                        message;
                                    this.dialogConfirmation = true;
                                    this.dialogResponse = true;
                                    this.mensagem = error;
                                    
                                }
                            );
                    } catch (e) {
                        this.mensagem = e;
                        console.log(e);
                        reject();
                    } finally {
                        this.dialogExtra = null;
                        this.labelConfirm = null;
                    }
                });
            },
         
            confirmarAlterarStatus(cd_status, irregularidade=null){

                let title = '';
                let text = '';

                switch(cd_status){
                    case 320:
                        title = 'Enviar para Negociação'
                        text = 'Deseja realmente enviar o titulo para Negociação?'
                        break;
                    case 220:
                        title = 'Enviar para Protesto'
                        text = 'Deseja realmente enviar o titulo para Protesto?'
                        break;

                    case 105:
                        title = 'Irregularizar Título'
                        text = `Deseja realmente irregularizar o título devido a comarca estar inválida?(105-${irregularidade})`
                        break;
                }

                this.irregularidadeTipo = irregularidade
                this.statusTitulo = cd_status
                this.tipo_path = 'alterar-status';
                this.genericDialog.title = title;
                this.genericDialog.text = text;
                this.genericDialog.labelConfirmBtn = 'Sim';
                this.genericDialog.labelCancelBtn = 'Não';
                this.dialogConfirmationGeneric = true;
                
            },

            confirmarReenvio(){
                this.tipo_path = 'reenvio';
                this.genericDialog.title = 'Reenviar Título';
                this.genericDialog.text = 'Deseja realmente reenviar para fila?';
                this.genericDialog.labelConfirmBtn = 'Sim';
                this.genericDialog.labelCancelBtn = 'Não';
                this.dialogConfirmationGeneric = true;

            },
            setFeedbackSnackbar(color, message) {
                this.feedbackSnackbarColor = color;
                this.mensagemfeedbackSnackbar = message;
                this.feedbackSnackbar = true;
            },
           
            alterarStatus() {

                const tituloService = new TituloService(Vue.http, this.$store);
                let cd_irregularidade = this.irregularidadeTipo ? this.irregularidadeTipo : null; 

                tituloService.updateStatus(this.titulo.detalhe_titulo.id_titulo, this.statusTitulo, cd_irregularidade).then(response => {
                    if (response.status == 200) {

                        let status_label = {
                            320: 'Negociação',
                            220: 'Protesto'
                        }

                        let msg = cd_irregularidade ? `Irregularizado título com sucesso por comarca inválida (105-${cd_irregularidade}).` : `Título enviado para ${status_label[this.statusTitulo]}!`;

                        this.setFeedbackSnackbar(
                            'success',
                            msg
                        );
                        this.getTitulo();
                        this.getComentarios();
                    }
                }).catch(() => {

                    let msg = cd_irregularidade ? 'Erro ao irregularizar título por comarca inválida.' : `Erro ao atualizar status do título.`;

                    this.setFeedbackSnackbar(
                        'error',
                        msg
                    );
                });

            },

            solicitaCancelamento() {
                this.tipo_path = 'cancelamento';
                this.tipo_cancelamento = 'o Cancelamento';
                this.dialogConfirmation = true;
                this.dialogExtra =
                    'O comando de cancelamento gera custos de responsabilidade do apresentante.';
                this.labelConfirm = 'Sim, estou ciente';
            },
            solicitaAnuencia(titulo) {
                console.log(titulo);
                this.tipo_path = 'anuencia';
                this.tipo_cancelamento = 'a Anuência';
                if ([221, 225].includes(titulo.cd_status)) {
                    this.modalInformativoPreAnuencia = true;
                }else{
                    this.dialogConfirmation = true;
                }
            },
            erroMensageria(){
                this.problemaMensageria = true;
            },
            getTitulo() {
                console.log(this.id_titulo, this.cd_apresentante);
                this.tituloService.detalharTituloView({id_titulo: this.id_titulo, cd_apresentante: this.cd_apresentante})
                    .then(response => {
                        this.titulo = response.data;
                        this.getHistoricoEmails();
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            getComentarios(){
                this.tituloService.listarComentarios(this.id_titulo)
                    .then(response => {
                        this.comentarios = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            getHistoricoEmails() {
                this.emailService.buscarEnviosPorDetalheTitulo({
                    cd_apresentante: this.cd_apresentante, 
                    ds_nosso_numero: this.titulo.detalhe_titulo.ds_nosso_numero
                })
                    .then(response => {
                        console.log(response.data)
                        this.historicoEmails = response.data.titulos;
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            setFeedbackError(message) {
                this.feedbackSnackbarColor = 'error'
                this.mensagemfeedbackSnackbar = message
                this.feedbackSnackbar = true
            }
        }
    }
</script>
<style>
.bg-view {
    background-color: #f5f5f5;
}
.printEffect{
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    /* width: 100px; */
    height: 100px;
    background-color: #ccc;
}
</style>