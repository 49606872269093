var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.mostrarAnexoProtesto() && _vm.verificaPermissaoAnexo())?_c('v-card',{staticClass:"ma-3 px-3 pt-3 pb-6",attrs:{"loading":_vm.loadingAnexo}},[_c('v-container',[_c('p',{ref:"sessaoAnexo",staticClass:"title"},[_c('v-icon',{staticClass:"ma-2"},[_vm._v("mdi-information-outline")]),_vm._v(" Anexo Arquivo de Protesto ")],1),(_vm.anexoProtesto != null)?_c('v-row',{staticClass:"mt-2 d-flex align-center"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4","md":"4"}},[(!_vm.arquivosSelecionadosTitulo)?_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"ID Anexo","required":"","readonly":"","hide-details":""},model:{value:(_vm.anexoProtesto.ds_anexo_nome),callback:function ($$v) {_vm.$set(_vm.anexoProtesto, "ds_anexo_nome", $$v)},expression:"anexoProtesto.ds_anexo_nome"}}):_c('div',[_c('span',[_vm._v(" Voce tem certeza que deseja substituir o anexo? ")]),_vm._v(" "+_vm._s(_vm.formatarNomesArquivos())+" "),(
                            _vm.arquivosSelecionadosTitulo &&
                                _vm.arquivosSelecionadosTitulo.length
                        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                on,
                                attrs
                            }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ma-3",attrs:{"color":_vm.tamanhoArquivosValido(
                                        _vm.arquivosSelecionadosTitulo
                                    )
                                        ? 'green'
                                        : 'red'}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.tamanhoArquivosValido( _vm.arquivosSelecionadosTitulo ) ? 'done' : 'error')+" ")])]}}],null,false,851203681)},[_c('span',[_vm._v(" "+_vm._s(_vm.tamanhoArquivosValido( _vm.arquivosSelecionadosTitulo ) ? 'Os arquivos selecionados estão dentro do tamanho máximo permitido de 5MB por Título' : 'A soma do tamanho dos arquivos não pode ultrapassar 5MB por Título')+" ")])]):_vm._e()],1)],1),(!_vm.arquivosSelecionadosTitulo)?_c('div',[_c('v-btn',{staticClass:"white--text ml-3",attrs:{"loading":_vm.loadingAnexo,"color":"green","width":"120px"},on:{"click":_vm.baixarArquivoProtesto}},[_vm._v("BAIXAR ")]),(_vm.titulo.cd_status == 220)?_c('v-btn',{staticClass:"white--text ml-3",attrs:{"loading":_vm.loadingAnexo || _vm.loading,"color":"blue","width":"auto"},on:{"click":function($event){_vm.document
                            .getElementById('pdfFileInputUpdate')
                            .click()}}},[_vm._v("substituir "),_c('v-icon',{staticClass:"mx-2"},[_vm._v("mdi-file-replace-outline")])],1):_vm._e(),_c('v-file-input',{staticStyle:{"display":"none"},attrs:{"accept":'application/pdf, application/zip',"hide-input":"","prepend-icon":"","multiple":"","id":"pdfFileInputUpdate"},on:{"change":function($event){return _vm.$forceUpdate()}},model:{value:(_vm.arquivosSelecionadosTitulo),callback:function ($$v) {_vm.arquivosSelecionadosTitulo=$$v},expression:"arquivosSelecionadosTitulo"}})],1):_c('div',[_c('v-btn',{staticClass:"white--text ml-3",attrs:{"color":"red","width":"auto"},on:{"click":function($event){_vm.arquivosSelecionadosTitulo = null}}},[_vm._v("cancelar "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-close")])],1),_c('v-btn',{staticClass:"white--text ml-3",attrs:{"loading":_vm.loadingAnexo || _vm.loading,"color":"green","width":"auto"},on:{"click":function($event){return _vm.substituirAnexo()}}},[_vm._v("confirmar "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-check")])],1)],1)],1):_c('div',[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"6","xs":"6","sm":"6","md":"7","lg":"7"}},[_vm._v(" Arquivo(s) anexado(s) ")]),_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"6","xs":"6","sm":"6","md":"5","lg":"5"}})],1),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-3 d-flex justify-start align-center",attrs:{"cols":"6","xs":"6","sm":"6","md":"7","lg":"7"}},[_vm._v(" "+_vm._s(_vm.formatarNomesArquivos())+" "),(
                            _vm.arquivosSelecionadosTitulo &&
                                _vm.arquivosSelecionadosTitulo.length
                        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                on,
                                attrs
                            }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ma-3",attrs:{"color":_vm.tamanhoArquivosValido(
                                        _vm.arquivosSelecionadosTitulo
                                    )
                                        ? 'green'
                                        : 'red'}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.tamanhoArquivosValido( _vm.arquivosSelecionadosTitulo ) ? 'done' : 'error')+" ")])]}}],null,false,851203681)},[_c('span',[_vm._v(" "+_vm._s(_vm.tamanhoArquivosValido( _vm.arquivosSelecionadosTitulo ) ? 'Os arquivos selecionados estão dentro do tamanho máximo permitido de 5MB por Título' : 'A soma do tamanho dos arquivos não pode ultrapassar 5MB por Título')+" ")])]):_vm._e()],1)],1),_c('v-row',{staticClass:"ma-0"},[_c('v-col',[(!_vm.anexoProtesto)?_c('span',{staticClass:"text-grey"},[_c('strong',[_vm._v("Arquivos aceitos:")]),_vm._v(" PDF ou ZIP com tamanho inferior à 4,5MB. Certifique-se que os arquivos não possuam carecteres especiais ")]):_vm._e()]),_c('v-col',{staticClass:"pa-0 d-flex justify-end align-center",attrs:{"cols":"4","xs":"4","sm":"4","md":"3","lg":"3"}},[_c('v-btn',{staticClass:"white--text ml-3",attrs:{"color":"green","disabled":_vm.anexoProtesto != null,"loading":_vm.loadingAnexo || _vm.loading},on:{"click":function($event){_vm.document
                                .getElementById('pdfFileInput')
                                .click()}}},[_vm._v("SELECIONAR ANEXO(s) ")]),(!_vm.pendenteSelecionarArquivos())?_c('v-btn',{staticClass:"white--text ml-3",attrs:{"color":"green","loading":_vm.loadingAnexo || _vm.loading,"disabled":!_vm.tamanhoArquivosValido(
                                _vm.arquivosSelecionadosTitulo
                            )},on:{"click":function($event){return _vm.anexarArquivoProtesto()}}},[_vm._v("ENVIAR ")]):_vm._e()],1)],1),_c('v-file-input',{staticStyle:{"display":"none"},attrs:{"accept":'application/pdf, application/zip',"hide-input":"","prepend-icon":"","multiple":"","id":"pdfFileInput"},on:{"change":function($event){return _vm.$forceUpdate()}},model:{value:(_vm.arquivosSelecionadosTitulo),callback:function ($$v) {_vm.arquivosSelecionadosTitulo=$$v},expression:"arquivosSelecionadosTitulo"}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }