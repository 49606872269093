<template>
    <v-card class="pa-3 card-mensageria">
        <v-row>
            <v-col>
                <h4 class="bold">Mensageria</h4>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col>
                <div class="list-alert">
                    <div v-if="superUsuario && usuarioRecuperi">
                        <v-icon :color="alertTypeEmail">{{alertTypeEmail === 'success' ? 'mdi-check-circle' :'mdi-alert-circle'}}</v-icon>
                        <span class="ml-2" v-html="alertInfoEmail"/>
                    </div>
                    <div class="mt-2" v-if="superUsuario && usuarioRecuperi">
                        <v-icon :color="alertTypeCel">{{alertTypeCel === 'success' ? 'mdi-check-circle' :'mdi-alert-circle'}}</v-icon>
                        <span class="ml-2" v-html="alertInfoCel"/>
                    </div>
                    <div class="mt-2" v-if="superUsuario && usuarioRecuperi">
                        <v-icon :color="alertTypeCelCheck">{{alertTypeCelCheck === 'success' ? 'mdi-check-circle' :'mdi-alert-circle'}}</v-icon>
                        <span class="ml-2" v-html="alertInfoCelCheck"/>
                    </div>
                    <div class="mt-2" v-if="superUsuario && usuarioRecuperi">
                        <v-icon :color="habilitadoNotifica ? 'success' : 'info'">{{habilitadoNotifica === 'success' ? 'mdi-check-circle' :'mdi-alert-circle'}}</v-icon>
                        <span v-if="habilitadoNotifica" class="ml-2" v-html="'<strong>Notifica:</strong> o Apresentante está habilitado na Notifica'"></span>
                        <span v-else class="ml-2" v-html="'<strong>Notifica:</strong> o Apresentante não está habilitado na Notifica'"></span>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
import moment from 'moment'
export default {
    name: 'CardMensageria',
    props: {
        titulo: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            alertTypeEmail: 'info',
            alertInfoEmail: 'Verificação: em andamento...',
            alertTypeCel: 'info',
            alertInfoCel: 'Verificação: em andamento...',
            emailCheck: false,
            stEnvioCheck: false,
            telefoneCheck: false,
            alertTypeOfertas: 'info',
            alertTypeCelCheck: 'info',
            alertInfoCelCheck: 'Verificação: em andamento...',
          
        }
    },
    computed:{
        superUsuario(){
            return !!this.$store.getters.getSuperUser;
        },
        usuarioRecuperi(){
            return this.$store.getters.isPartner != 1;
        },
        habilitadoNotifica() {
            return this.$store.getters.apresentanteNotifica;
        }

    },
    mounted() {
        this.verificaMensageria();
    },    
    methods: {
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY')
        },
        montaInformacoes() {
            if (this.emailCheck && this.stEnvioCheck) {
                this.alertTypeEmail = 'success';
                this.alertInfoEmail =
                    '<strong>E-mail</strong>: Título Pronto para receber mensagens de e-mail';
            }
            if (this.emailCheck && !this.stEnvioCheck) {
                this.$emit('erroMensageria')
                this.alertTypeEmail = 'info';
                this.alertInfoEmail =
                    '<strong>E-mail:</strong> e-mail OK, porém falta ativar e-mail callback (atualmente está (0))';
            }
            if (!this.emailCheck && !this.stEnvioCheck) {
                this.$emit('erroMensageria')
                this.alertTypeEmail = 'info';
                this.alertInfoEmail =
                    '<strong>E-mail:</strong> Atenção, título não possui e-mail cadastrado e situação de callback atualmente é (0)';
            }
            if (this.telefoneCheck) {
                this.alertTypeCel = 'success';
                this.alertInfoCel =
                    '<strong>Telefone:</strong> Título possui telefone. (Campo telefone informado)';
            }

            if (this.titulo.cd_status === 102) {
                this.alertTypeOfertas = 'success';
                this.alertInfoOfertas =
                    'Esse Título está pronto para receber ofertas na plataforma Protestado. (status 102:Protestado)';
            }

            this.checkNumberPhone();
        },
        verificaMensageria() {
            if (this.titulo.ds_email_devedor) {
                this.emailCheck = true;
            }
            if (this.titulo.st_email_callback == 1) {
                this.stEnvioCheck = true;
            }
            if (this.titulo.nu_telefone_devedor) {
                this.telefoneCheck = true;
            }
            this.montaInformacoes();
        },
        checkNumberPhone() {
            const myString = this.titulo.nu_telefone_devedor;
            const regex = /^(?=.{10,11}$)..(99|88)/;
            const containsNumber = regex.test(myString);

            if (containsNumber) {
                this.alertTypeCelCheck = 'success';
                this.alertInfoCelCheck =
                    '<strong>Telefone:</strong> O número informado no título é um celular: pronto para receber Whatsapp/SMS (número possui 10/11 dígitos)';
            } else {
                this.$emit('erroMensageria')
                this.alertTypeCelCheck = 'info';
                this.alertInfoCelCheck =
                    '<strong>Telefone:</strong> O Telefone informado no título não é um telefone Celular. Não poderá receber mensagens de SMS/Whatsapp';
            }
        },
    }
}

</script>
<style lang="scss" scoped>
.list-alert {
    max-height: 350px;
    overflow: auto;
    padding: 10px 0px;

}
.list-alert {
    scrollbar-width: thin;
    scrollbar-color: #888 #fff;
}

/* Works on Chrome, Edge, and Safari */
.list-alert::-webkit-scrollbar {
    width: 12px;
}
.list-alert::-webkit-scrollbar-track {
    background: #fff;
}
.list-alert::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 20px;
    border: 3px solid #fff;
}
</style>